/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, jsx, Spinner, Text } from 'theme-ui'
import {
  useAnalytics,
  useUser,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'
import FormInput from '~/components/Generic/Form/Input'
import useUiTextQuery from '~/hooks/graphql/queries/use-ui-text'
import { trackReferralRequest } from '~/services/analytics/client'

const ReferralsForm = () => {
  const { trackEmailCaptured } = useAnalytics()
  const { register, handleSubmit, errors } = useForm()
  const [error, setError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const translate = useTranslate()
  const referralsUiText = useUiTextQuery('global', 'referrals')
  const { loadUserReferralIdentifier, user } = useUser()

  const onSubmit = async (data) => {
    try {
      setError(null)
      setIsSubmitting(true)
      setSubmitted(false)

      const { referrerEmail } = data
      const referralIdentifier =
        user?.referralIdentifier ||
        (await loadUserReferralIdentifier({ email: referrerEmail }))

      if (referrerEmail && !user?.data?.email) {
        await trackEmailCaptured({
          emailCapture: {
            email: referrerEmail,
            page: 'referral',
            component: 'form',
            website: window.location.hostname,
          },
        })
      }

      if (referralIdentifier?.purl) {
        await trackReferralRequest({ ...data, referralIdentifier }, user)
        setIsSubmitting(false)
        setSubmitted(true)
      } else {
        setError(translate('error.api.default'))
        setIsSubmitting(false)
        setSubmitted(false)
        throw new Error('Missing referral identifier')
      }
    } catch (error) {
      console.error(error, {
        source: 'ReferralsForm.onSubmit',
      })
    }
  }

  return (
    <Box sx={{ textAlign: 'left' }}>
      <form
        name="refer-a-friend"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ position: 'relative' }}
      >
        <Box mb={[2, 3]}>
          <FormInput
            name="referrerName"
            title={'Your name'}
            label={'Your name'}
            aria-label={'Your name'}
            required={true}
            type="text"
            defaultValue={user?.data?.name}
            error={
              errors &&
              errors['referrerName'] &&
              errors['referrerName'].type === 'required'
                ? 'This field is required.'
                : null
            }
            ref={register({
              required: true,
            })}
          />
        </Box>
        <Box mb={[2, 3]}>
          <FormInput
            name="referrerEmail"
            title={'Your email address'}
            label={'Your email address'}
            aria-label={'Your email address'}
            required={true}
            type="email"
            defaultValue={user?.data?.email}
            error={
              errors &&
              errors['referrerEmail'] &&
              errors['referrerEmail'].type === 'required'
                ? 'This field is required.'
                : null
            }
            ref={register({
              required: true,
              pattern: {
                value: /^\S+@\S+$/i,
                message: translate('validation.invalid_email'),
              },
            })}
          />
        </Box>
        <Box mb={[2, 3]}>
          <FormInput
            name="referredEmail"
            title={'Their email address'}
            label={'Their email address'}
            aria-label={'Their email address'}
            required={true}
            type="email"
            error={
              errors &&
              errors['referredEmail'] &&
              errors['referredEmail'].type === 'required'
                ? 'This field is required.'
                : null
            }
            ref={register({
              required: true,
              pattern: {
                value: /^\S+@\S+$/i,
                message: translate('validation.invalid_email'),
              },
            })}
          />
        </Box>
        <Box mt={[3, 4]}>
          <Button sx={{ width: '100%' }} type="submit">
            {!isSubmitting && 'Submit'}
            {isSubmitting && (
              <Spinner
                size="24"
                color="inherit"
                mb={-1}
                sx={{
                  position: 'relative',
                  top: '-2px',
                  '@media (prefers-reduced-motion)': {
                    circle: {
                      animationIterationCount: 8,
                    },
                  },
                }}
              />
            )}
          </Button>
        </Box>
        {error && (
          <Text as="p" color="error" variant="body2" mt="2">
            {error}
          </Text>
        )}
      </form>
      {submitted && (
        <Flex mt={3} sx={{ alignItems: 'center' }}>
          <Box mr={2} sx={{ flexShrink: '0' }}>
            <img
              src="/images/icons/cheers.svg"
              alt="An icon of two glasses touching each other"
            />
          </Box>
          <Text as="p" variant="body3">
            {referralsUiText.success}
          </Text>
        </Flex>
      )}
    </Box>
  )
}

export default ReferralsForm
