/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, jsx, Spinner, Text } from 'theme-ui'
import FormSelect from '~/components/Generic/Form/Select'
import { useAria } from '~/hooks/components/use-aria'
import { updateSubscriptionPreferences } from '~/services/chord/client'

const AccountDashboardAccountSubscriptionPreferenceForm = ({
  loadSubscription,
  setIsEditing,
  subscription,
  currentType,
  typeOptions,
  currentQuantity,
  quantityOptions,
}) => {
  const { updateAriaLive } = useAria()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const { register, handleSubmit, watch } = useForm()

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    setError(null)

    try {
      // Update subscription tags and line items.
      await updateSubscriptionPreferences({
        id: subscription.id,
        email: subscription.user.email,
        qty: data.defaultQuantity,
        type: data.defaultType,
        by: 'user',
      })

      await loadSubscription()
    } catch (error) {
      console.error(error)
      setError('Something went wrong. Please refresh the page and try again.')
    }

    setIsEditing(false)
    setIsSubmitting(false)
    updateAriaLive('Your changes were saved.')
  }

  const watchDefaultType = watch('defaultType', currentType?.key)
  const watchDefaultQuantity = watch('defaultQuantity', currentQuantity?.key)

  return (
    <Box mt={2}>
      <form
        name={`edit-subscription-preferences`}
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box mb={[2, 3]}>
          <FormSelect
            name="defaultType"
            title={'Varietals'}
            label={'Varietals'}
            aria-label={'Varietals'}
            defaultValue={currentType?.key}
            ref={register()}
          >
            {typeOptions.map((option) => (
              <option key={`type-option-${option.key}`} value={option.key}>
                {option.label}
              </option>
            ))}
          </FormSelect>
        </Box>
        <Box mb={[2, 3]}>
          <FormSelect
            name="defaultQuantity"
            title={'Quantity'}
            label={'Quantity'}
            aria-label={'Quantity'}
            defaultValue={currentQuantity?.key}
            ref={register()}
          >
            {quantityOptions.map((option) => (
              <option key={`quantity-option-${option.key}`} value={option.key}>
                {option.label}
              </option>
            ))}
          </FormSelect>
        </Box>
        {error && (
          <Text as="p" variant="error">
            {error}
          </Text>
        )}
        {(watchDefaultType !== currentType?.key ||
          watchDefaultQuantity !== currentQuantity?.key) && (
          <Text as="p" variant="error" mb={2}>
            This will override any customizations you've made to your next
            shipment, but you'll be able to customize again after saving.
          </Text>
        )}
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="primaryRounded"
            type="submit"
            mb={1}
            sx={{ order: [null, '1'], width: ['100%', 'auto'] }}
          >
            {!isSubmitting && 'Save'}
            {isSubmitting && (
              <Spinner
                size="24"
                color="inherit"
                mb={-1}
                sx={{
                  position: 'relative',
                  top: '-2px',
                  '@media (prefers-reduced-motion)': {
                    circle: {
                      animationIterationCount: 8,
                    },
                  },
                }}
                title="Saving"
              />
            )}
          </Button>
          <Button
            variant="reset"
            type="button"
            aria-label="Chancel changes"
            onClick={() => setIsEditing(false)}
            sx={{ order: [null, '0'] }}
          >
            <Text
              as="span"
              variant="body3"
              sx={{ color: 'brandPrimary', textDecoration: 'underline' }}
            >
              Cancel
            </Text>
          </Button>
        </Flex>
      </form>
    </Box>
  )
}

export default AccountDashboardAccountSubscriptionPreferenceForm
