/** @jsx jsx */
import { Box, jsx, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const PackCallout = ({ pack }) => {
  const { canClubOnly, comingSoon, customLabel, soldOut } = pack

  let backgroundColor = 'brandSecondary'
  let label = ''

  const translate = useTranslate()

  if (soldOut) {
    backgroundColor = 'greyDark'
    label = translate('product.sold_out_join')
  } else if (comingSoon) {
    label = translate('product.coming_soon')
  } else if (canClubOnly) {
    backgroundColor = 'brandPrimary'
    label = translate('product.can_club_only')
  } else if (customLabel) {
    label = customLabel
  }

  if (label === '') {
    return null
  }

  return (
    <Box
      p={1}
      sx={{
        backgroundColor,
        color: 'white',
        display: 'inline-block',
        px: '16px',
        py: '12px',
        pointerEvents: 'none',
      }}
    >
      <Text as="p" variant="callout2">
        {label}
      </Text>
    </Box>
  )
}

export default PackCallout
