/** @jsx jsx */
import { Box, Grid, Heading, jsx, Text } from 'theme-ui'
import useEventProductsQuery from '~/hooks/graphql/queries/use-event-products'
import AccountDashboardCommunityEventCard from './EventCard'
import AccountDashboardCommunityTastingPanelForm from './TastingPanelForm'

const AccountDashboardCommunityPanel = () => {
  const { packs } = useEventProductsQuery()

  return (
    <Box>
      <Box mb={[5, 6]}>
        <Box mb={[3, 5]}>
          <Heading as="h3" variant="title3" mb={1}>
            {'Upcoming events'}
          </Heading>
          <Text as="p" variant="body2" m={0}>
            Join our virtual events and let us bring wine country to you! When
            you purchase event wines, you’ll automatically be signed up for the
            event and receive a Zoom link to your inbox.
          </Text>
        </Box>
        {packs && (
          <Box as="ul" m={0} p={0}>
            {packs.map((pack, index) => (
              <Box
                as="li"
                key={`event-pack-${pack.slug}-${index}`}
                sx={{
                  listStyleType: 'none',
                  '&:not(:last-of-type)': {
                    mb: 3,
                  },
                }}
              >
                <AccountDashboardCommunityEventCard pack={pack} />
              </Box>
            ))}
          </Box>
        )}
        {!packs && (
          <Text as="p" variant="body1">
            {'There are currently no upcoming events. Please check back soon!'}
          </Text>
        )}
      </Box>
      <Box
        bg="backgroundMedium"
        mb={[-14]}
        mx={[-2, -3]}
        pt={5}
        pb={[14]}
        px={[2, 3]}
      >
        <Grid columns={[null, null, null, 9]}>
          <Box sx={{ gridColumn: [null, null, null, '1 / 8'] }}>
            <AccountDashboardCommunityTastingPanelForm />
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default AccountDashboardCommunityPanel
