/** @jsx jsx */
import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Box, jsx, Spinner } from 'theme-ui'
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy'
import { useAria } from '~/hooks/components/use-aria'
import { getLoginUrl } from '~/utils/auth'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { updateAriaLive } = useAria()
  const { isLoggedIn, isFetching } = useAuth()

  useEffect(() => {
    if (!isLoggedIn && !isFetching) {
      updateAriaLive('Log in to access this page. You are being redirected.')
      navigate(`/${getLoginUrl()}/`)
    }
  }, [isLoggedIn, isFetching, updateAriaLive])

  if (!isLoggedIn) {
    return (
      <Box py={10} sx={{ textAlign: 'center' }}>
        <Spinner
          color="brandTertiary"
          size="100"
          sx={{
            '@media (prefers-reduced-motion)': {
              circle: {
                animationIterationCount: 8,
              },
            },
          }}
        />
      </Box>
    )
  }

  return <Component {...rest} />
}

export default PrivateRoute
