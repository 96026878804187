import { graphql, useStaticQuery } from 'gatsby'

const useEventProductsQuery = () => {
  const data = useStaticQuery(graphql`
    query EventProductsCollectionQuery {
      collection: contentfulCollection(slug: { eq: "event-products" }) {
        packs: listings {
          ... on ContentfulPack {
            canClubOnly
            comingSoon
            customLabel
            eventDate
            longDescription {
              childMarkdownRemark {
                html
              }
            }
            mainImage {
              description
              gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 300)
            }
            name
            slug
            slugUrl
            soldOut
          }
        }
      }
    }
  `)

  return data.collection
}

export default useEventProductsQuery
