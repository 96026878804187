/** @jsx jsx */
import moment from 'moment'
import { Box, Button, Flex, Grid, jsx, Text } from 'theme-ui'
import { getFormattedBottleCount } from '~/utils/products'

const AccountDashboardClubPastShipment = ({ order }) => {
  const {
    completedAt,
    displayItemTotal,
    displayOrderTotalAfterStoreCredit,
    lineItems,
    number,
    shipments,
  } = order

  const canLineItems = lineItems.filter(
    (lineItem) =>
      !lineItem.variant.name.includes('Gift') && !lineItem.variant?.hidden
  )
  const canQuantity = canLineItems.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  )

  return (
    <Box bg="white" py={3} px={[2, 3]} sx={{ borderRadius: '10px' }}>
      <Flex
        pb={2}
        sx={{
          alignItems: [null, 'center'],
          borderBottom: '1px solid',
          borderBottomColor: 'greyLight',
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Box mr={[null, 2]}>
          <Text as="p" variant="subtitle2" mb={1}>
            {moment(completedAt).format('MMMM D, YYYY')}
          </Text>
          <Text as="p" variant="body2">
            <Text as="span" sx={{ fontWeight: 'bold' }}>
              {`${canQuantity} cans`}
            </Text>
            <Text as="span" mr={1}>
              {` (${getFormattedBottleCount(canQuantity)} bottle equivalent):`}
            </Text>
            <br sx={{ display: ['none', 'block', null, 'none'] }} />
            {displayItemTotal !== displayOrderTotalAfterStoreCredit && (
              <Text
                as="span"
                sx={{ color: 'greyDark', textDecoration: 'line-through' }}
              >
                {displayItemTotal}{' '}
              </Text>
            )}
            <Text as="span" sx={{ fontWeight: 'bold' }}>
              {displayOrderTotalAfterStoreCredit}
            </Text>
          </Text>
        </Box>
        {shipments && shipments.length > 0 && shipments[0].externalTrackingUrl && (
          <Button
            as="a"
            mt={[2, 0]}
            variant="primaryRounded"
            href={shipments[0].externalTrackingUrl}
            target="_blank"
            sx={{ flexShrink: '0', width: ['100%', 'auto'] }}
          >
            {'Track shipment'}
          </Button>
        )}
      </Flex>
      <Grid columns={1} pt={2} sx={{ rowGap: 2 }}>
        {canLineItems.map((lineItem, index) => (
          <Text
            key={`${number}-${lineItem.variant.sku}`}
            as="p"
            variant="title6"
            m={0}
          >
            <Text as="span">{lineItem.variant.name}</Text>
            <Text as="span">
              {` (${lineItem.quantity} can${lineItem.quantity > 1 ? 's' : ''})`}
            </Text>
          </Text>
        ))}
      </Grid>
    </Box>
  )
}

export default AccountDashboardClubPastShipment
