/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'gatsby'
import { Box, Button, Flex, Grid, Heading, jsx, Spinner, Text } from 'theme-ui'
import { useSubscriptions, useUser } from '@chordcommerce/gatsby-theme-autonomy'
import AccountDashboardAccountSubscriptionCard from './SubscriptionCard'
import FormInput from '~/components/Generic/Form/Input'
import { useAria } from '~/hooks/components/use-aria'
import { sortActiveSubscriptions } from '~/utils/subscriptions'

const AccountDashboardAccountEditInfo = ({ setIsEditingInfo }) => {
  const { updateAriaLive } = useAria()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { register, handleSubmit, errors } = useForm()
  const { modifyUser, user } = useUser()

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    await modifyUser({
      attributes: {
        email: data?.email,
        name: data?.name,
        phone: data?.phone,
      },
    })
    setIsEditingInfo(false)
    setIsSubmitting(false)
    updateAriaLive('Your changes were saved.')
  }

  return (
    <form name={`edit-info`} method="POST" onSubmit={handleSubmit(onSubmit)}>
      <Box mb={[2, 3]}>
        <FormInput
          name="name"
          title={'Name'}
          label={'Name'}
          aria-label={'Name'}
          type="text"
          defaultValue={user?.data?.name}
          ref={register()}
        />
      </Box>
      <Box mb={[2, 3]}>
        <FormInput
          name="email"
          title={'Email address'}
          label={'Email address'}
          aria-label={'Email address'}
          required={true}
          type="email"
          defaultValue={user?.data?.email}
          error={
            errors && errors['email'] && errors['email'].type === 'required'
              ? 'This field is required.'
              : null
          }
          ref={register({
            required: true,
          })}
        />
      </Box>
      <Box mb={[2, 3]}>
        <FormInput
          name="phone"
          title={'Phone number'}
          label={'Phone number'}
          aria-label={'Phone number'}
          type="text"
          defaultValue={user?.data?.phone}
          ref={register()}
        />
      </Box>
      <Flex
        sx={{
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="primaryRounded"
          type="submit"
          mb={1}
          sx={{ order: [null, '1'], width: ['100%', 'auto'] }}
        >
          {!isSubmitting && 'Save'}
          {isSubmitting && (
            <Spinner
              size="24"
              color="inherit"
              mb={-1}
              sx={{
                position: 'relative',
                top: '-2px',
                '@media (prefers-reduced-motion)': {
                  circle: {
                    animationIterationCount: 8,
                  },
                },
              }}
              title="Saving"
            />
          )}
        </Button>
        <Button
          variant="reset"
          type="button"
          onClick={() => setIsEditingInfo(false)}
          sx={{ order: [null, '0'] }}
        >
          <Text
            as="span"
            variant="body3"
            sx={{ color: 'brandPrimary', textDecoration: 'underline' }}
          >
            Cancel
          </Text>
        </Button>
      </Flex>
    </form>
  )
}

const AccountDashboardAccountPanel = ({ allSubscriptionContent }) => {
  const [isEditingInfo, setIsEditingInfo] = useState(false)
  const { user } = useUser()
  const { subscriptions } = useSubscriptions()

  const { data } = user
  const { email, name, phone } = data

  const activeSubscriptions = sortActiveSubscriptions(subscriptions)

  return (
    <Grid columns={[1, 12, 8, 9]} sx={{ rowGap: 5 }}>
      <Box sx={{ gridColumn: [null, '1 / 8', '1 / 6', '1 / 7'] }}>
        <Box mb={[5, null, 6]}>
          <Heading as="h2" variant="title3" mb={3}>
            {'Account settings'}
          </Heading>
          {email && (
            <Box bg="white" py={3} px={[2, 3]} sx={{ position: 'relative' }}>
              {!isEditingInfo && (
                <Button
                  variant="reset"
                  onClick={() => setIsEditingInfo(true)}
                  aria-label="Edit account information"
                  sx={{
                    position: 'absolute',
                    right: ['16px', '24px'],
                    top: '24px',
                  }}
                >
                  <Text
                    as="span"
                    variant="description"
                    sx={{ color: 'brandPrimary', textDecoration: 'underline' }}
                  >
                    Edit
                  </Text>
                </Button>
              )}
              <Heading as="h3" variant="title4" mb={1}>
                {!isEditingInfo && 'Account info'}
                {isEditingInfo && 'Edit account info'}
              </Heading>
              {!isEditingInfo && (
                <Text as="p" variant="body2" m={0}>
                  {name}
                  {name && <br />}
                  {email}
                  {email && <br />}
                  {phone}
                </Text>
              )}
              {isEditingInfo && (
                <AccountDashboardAccountEditInfo
                  setIsEditingInfo={setIsEditingInfo}
                />
              )}
            </Box>
          )}
          {!email && (
            <Box sx={{ textAlign: 'center' }}>
              <Spinner
                color="brandTertiary"
                size="40"
                sx={{
                  '@media (prefers-reduced-motion)': {
                    circle: {
                      animationIterationCount: 8,
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>
        {activeSubscriptions && activeSubscriptions.length > 0 && (
          <Box>
            <Heading as="h2" variant="title3" mb={1}>
              {`Club membership${activeSubscriptions.length > 1 ? 's' : ''}`}
            </Heading>
            <Text as="p" variant="body3" mb={3}>
              Visit <Link to="/account/club">your account's club page</Link> to
              customize or skip club orders.
            </Text>
            {activeSubscriptions.map(({ id }) => (
              <AccountDashboardAccountSubscriptionCard
                key={`subscription-card-${id}`}
                allSubscriptionContent={allSubscriptionContent}
                email={email}
                subscriptionId={id}
              />
            ))}
          </Box>
        )}
      </Box>
      <Box sx={{ gridColumn: [null, '8 / -1', '6 / -1', '7 / -1'] }}>
        <Box
          bg="backgroundMedium"
          mt={[null, '56px', null, '57px']}
          mx={[-2, 0]}
          mb={[-14, 0]}
          pt={[5, 3]}
          pb={[14, 3]}
          px={[2, 3]}
          sx={{ position: [null, 'sticky'], top: '128px' }}
        >
          <Heading as="h3" variant="title4" mb={1}>
            {'Want to chat?'}
          </Heading>
          <Text as="p" variant="body3" mb={3}>
            We'd love to hear from you and answer any questions you may have.
          </Text>
          <Heading as="h4" variant="body3" mb="4px" sx={{ fontWeight: 'bold' }}>
            {'Email us:'}
          </Heading>
          <Text
            as="p"
            variant="title6"
            m={0}
            sx={{
              a: {
                color: 'black',
                textDecoration: 'none',
                '&:hover, &:focus': { textDecoration: 'underline' },
              },
            }}
          >
            <a href="mailto:support@makerwine.com">
              support@
              <br
                sx={{
                  display: 'none',
                  '@media only screen and (min-width: 640px) and (max-width: 680px)':
                    {
                      display: 'block',
                    },
                  '@media only screen and (min-width: 832px) and (max-width: 1124px)':
                    {
                      display: 'block',
                    },
                }}
              />
              makerwine.com
            </a>
          </Text>
        </Box>
      </Box>
    </Grid>
  )
}

export default AccountDashboardAccountPanel
