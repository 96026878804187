/** @jsx jsx */
import { Box, Grid, Heading, jsx } from 'theme-ui'
import AccountDashboardClubUpcomingMessage from './UpcomingMessage'
import AccountDashboardClubUpcomingShipment from './UpcomingShipment'

const AccountDashboardClubUpcomingShipments = ({
  allProductContent,
  allSubscriptionContent,
  subscriptionIds,
}) => {
  return (
    <Box>
      <Heading as="h3" variant="title3" mb={3}>
        {`Next Club shipment${subscriptionIds.length > 1 ? 's' : ''}:`}
      </Heading>
      <Grid columns={1} sx={{ rowGap: 3 }}>
        <AccountDashboardClubUpcomingMessage
          subscriptionId={subscriptionIds?.[0]}
        />
        {subscriptionIds.map((subscriptionId, index) => (
          <AccountDashboardClubUpcomingShipment
            key={`upcoming-shipment-${subscriptionId}`}
            allProductContent={allProductContent}
            allSubscriptionContent={allSubscriptionContent}
            subscriptionId={subscriptionId}
          />
        ))}
      </Grid>
    </Box>
  )
}

export default AccountDashboardClubUpcomingShipments
