/** @jsx jsx */
import { useState } from 'react'
import { Link } from 'gatsby'
import {
  Box,
  Button,
  Flex,
  Heading,
  jsx,
  Spinner,
  Text,
  Textarea,
} from 'theme-ui'
import { identify } from '@chordcommerce/chord-js-autonomy/dist/analytics/utils'
import { addUserToKlaviyoList } from '~/services/klaviyo/client'

const AccountDashboardAccountSubscriptionCancelForm = ({
  email,
  id,
  setIsCanceling,
  cancelSubscription,
}) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [cancelReason, setCancelReason] = useState(null)

  const handleCancel = async () => {
    setIsProcessing(true)

    try {
      await cancelSubscription()

      if (cancelReason) {
        await identify('', { email, [`club_cancel_${id}`]: cancelReason })
      }

      addUserToKlaviyoList(
        email,
        process.env.GATSBY_KLAVIYO_LIST_ID_CLUB_CANCEL
      )

      setIsCanceled(true)
    } catch (error) {
      console.error(error, {
        source: 'AccountDashboardAccountSubscriptionCancelForm',
      })
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <Box>
      {!isCanceled && (
        <Box>
          <Heading as="h4" variant="title4" mb={1}>
            Are you sure you want to cancel?
          </Heading>
          <Text as="p" mt={0} mb={3} variant="body3">
            Would you rather{' '}
            <Link to="/account/club/">skip your next shipment</Link>? You can
            also contact us to switch between the 12 and 24 Clubs without
            canceling. By canceling you will not receive any additional club
            deliveries or discounts.
          </Text>
          <Heading as="h5" variant="title6" mb={2}>
            Please share your reason for canceling:
          </Heading>
          <Textarea
            mb={3}
            rows={2}
            value={cancelReason || ''}
            onChange={(e) => setCancelReason(e.target.value)}
            sx={{
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: 'greyMedium',
              borderRadius: 0,
              color: 'black',
              fontFamily: 'body',
              fontSize: '18px',
              letterSpacing: '0.5px',
              lineHeight: '30px',
              width: '100%',
            }}
          />
          <Flex
            sx={{
              alignItems: [null, null, null, 'center'],
              flexDirection: ['column', null, null, 'row'],
              justifyContent: [null, null, null, 'space-between'],
            }}
          >
            <Button
              variant="primaryRounded"
              onClick={() => handleCancel()}
              mb={[2, null, null, 0]}
              sx={{
                minWidth: '209px',
                order: [null, null, null, '1'],
                width: ['100%', null, null, 'auto'],
              }}
            >
              {!isProcessing && 'Cancel subscription'}
              {isProcessing && (
                <Spinner
                  size="15"
                  color="inherit"
                  sx={{
                    '@media (prefers-reduced-motion)': {
                      circle: {
                        animationIterationCount: 8,
                      },
                    },
                  }}
                />
              )}
            </Button>
            <Button variant="reset" onClick={() => setIsCanceling(false)}>
              <Text as="span" variant="textLink">
                Never mind
              </Text>
            </Button>
          </Flex>
        </Box>
      )}
      {isCanceled && (
        <Box>
          <Heading as="h4" variant="title4" mb={1}>
            We're sorry to see you go!
          </Heading>
          <Text as="p" mt={0} variant="body3">
            Please contact us if this may have been a mistake. You can also
            always sign up again on the{' '}
            <Link to="/can-club/">Can Club page</Link>.
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default AccountDashboardAccountSubscriptionCancelForm
