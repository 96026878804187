/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, Heading, jsx, Spinner, Text } from 'theme-ui'
import { useOrderHistory } from '~/hooks/components/use-order-history'
import AccountDashboardOrdersOrderCard from './OrderCard'

const AccountDashboardOrdersPanel = () => {
  const { getAlaCarteOrderHistory, isFetching } = useOrderHistory()

  const orders = getAlaCarteOrderHistory()

  return (
    <Box>
      <Heading as="h3" variant="title3" mb={1}>
        {'Order history'}
      </Heading>
      {isFetching && (
        <Box sx={{ textAlign: 'center' }}>
          <Spinner
            color="brandTertiary"
            size="40"
            sx={{
              '@media (prefers-reduced-motion)': {
                circle: {
                  animationIterationCount: 8,
                },
              },
            }}
          />
        </Box>
      )}
      {!isFetching && orders?.length > 0 && (
        <Box>
          <Text
            as="p"
            m={0}
            variant="description"
            mb={3}
            sx={{ color: 'greyDark' }}
          >{`${orders.length} order${orders.length > 1 ? 's' : ''}`}</Text>
          {orders.map((order, index) => (
            <AccountDashboardOrdersOrderCard
              key={`order-${order.id}-${index}`}
              order={order}
            />
          ))}
        </Box>
      )}
      {!isFetching && orders && orders.length === 0 && (
        <Box>
          <Text as="p" variant="body1" m={0}>
            You haven't placed any orders. Looking for{' '}
            <Link to="/account/club">club orders</Link>?
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default AccountDashboardOrdersPanel
