/** @jsx jsx */
import { Fragment, useState } from 'react'
import { Button, Heading, jsx, Text } from 'theme-ui'
import AccountDashboardAccountSubscriptionAddressForm from './SubscriptionAddressForm'

const AccountDashboardAccountPayment = ({
  subscription,
  updateSubscription,
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const { billAddress, payment } = subscription

  return (
    <Fragment>
      {!isEditing && (
        <Button
          variant="reset"
          aria-label="Edit club payment information"
          onClick={() => setIsEditing(true)}
          sx={{
            position: 'absolute',
            right: '0',
            top: '12px',
          }}
        >
          <Text
            as="span"
            variant="description"
            sx={{ color: 'brandPrimary', textDecoration: 'underline' }}
          >
            Edit
          </Text>
        </Button>
      )}
      <Heading as="h5" variant="title5" mb="4px">
        {!isEditing && 'Club payment'}
        {isEditing && 'Edit club payment'}
      </Heading>
      {!isEditing && payment?.source?.lastDigits && (
        <Text as="p" variant="body2" m={0}>
          <Text as="span" sx={{ textTransform: 'uppercase' }}>
            {`${payment.source.ccType} **** ${payment.source.lastDigits} ${payment.source.month}/${payment.source.year}`}
          </Text>
          <br />
          {billAddress.name}
          <br />
          {billAddress.address1}
          {billAddress.address2 && ` ${billAddress.address2}`}
          <br />
          {`${billAddress.city}, ${billAddress.stateText} ${billAddress.zipcode}`}
        </Text>
      )}
      {!isEditing && !payment?.source?.lastDigits && (
        <Text as="p" variant="body2" m={0} sx={{ color: 'error' }}>
          No card on file.{' '}
          <Text
            as="span"
            onClick={() => setIsEditing(true)}
            sx={{
              color: 'brandPrimary',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            Add a payment method.
          </Text>
        </Text>
      )}
      {isEditing && (
        <AccountDashboardAccountSubscriptionAddressForm
          addressType="billAddress"
          setIsEditing={setIsEditing}
          subscription={subscription}
          updateSubscription={updateSubscription}
        />
      )}
    </Fragment>
  )
}

export default AccountDashboardAccountPayment
