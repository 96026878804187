/** @jsx jsx */
import { useState } from 'react'
import { Box, Button, jsx } from 'theme-ui'
import ChevronIcon from '~/assets/images/icons/chevron.inline.svg'
import DropdownOption from '~/components/Generic/Dropdown/Option'
import DropdownOptions from '~/components/Generic/Dropdown/Options'
import { useAria } from '~/hooks/components/use-aria'

const Dropdown = ({
  ariaLabel,
  currentOption,
  disabled = false,
  options,
  optionsLabel,
  setOption,
}) => {
  const { updateAriaLive } = useAria()
  const [isOpen, setIsOpen] = useState(false)

  const handleSelectedOption = (option) => {
    setIsOpen(false)
    setOption(option)
  }

  const handleTriggerClick = () => {
    setIsOpen(true)
    updateAriaLive(
      `You have opened ${ariaLabel}. Select from ${options.length} options.`
    )
  }

  return (
    <Box
      sx={{
        opacity: disabled ? '1' : '1',
        position: 'relative',
      }}
    >
      <Button
        variant="reset"
        aria-label={`${ariaLabel} dropdown. Click to ${optionsLabel}.`}
        onClick={() => handleTriggerClick()}
        sx={{
          backgroundColor: 'white',
          borderColor: 'greyMedium',
          borderRadius: '26px',
          borderStyle: 'solid',
          borderWidth: '1px',
          display: 'flex',
          cursor: 'pointer',
          fontFamily: 'body',
          height: '48px',
          overflow: 'hidden',
          padding: '7px 48px 12px 24px',
          pointerEvents: disabled ? 'none' : 'auto',
          position: 'relative',
          transition: 'border-color 0.3s ease',
          width: '100%',
          '&:hover': {
            borderColor: 'greyDark',
          },
        }}
      >
        <DropdownOption disabled={disabled} option={currentOption} />
        <ChevronIcon
          sx={{
            color: disabled ? 'greyDark' : 'black',
            display: 'block',
            pointerEvents: 'none',
            position: 'absolute',
            right: '24px',
            top: 'calc(50% - 4px)',
          }}
        />
      </Button>
      {isOpen && (
        <DropdownOptions
          currentOption={currentOption}
          handleSelectedOption={handleSelectedOption}
          options={options}
          optionsLabel={optionsLabel}
        />
      )}
    </Box>
  )
}

export default Dropdown
