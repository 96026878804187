/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, Grid, Heading, jsx, Spinner, Text } from 'theme-ui'
import { useOrderHistory } from '~/hooks/components/use-order-history'
import AccountDashboardClubPastShipment from './PastShipment'

const AccountDashboardClubPastShipments = () => {
  const { getClubOrderHistory, isFetching } = useOrderHistory()

  const orders = getClubOrderHistory()

  return (
    <Box>
      <Heading as="h3" variant="title3" mb={3}>
        {`Past Club shipments:`}
      </Heading>
      {isFetching && (
        <Box sx={{ textAlign: 'center' }}>
          <Spinner
            color="brandTertiary"
            size="40"
            sx={{
              '@media (prefers-reduced-motion)': {
                circle: {
                  animationIterationCount: 8,
                },
              },
            }}
          />
        </Box>
      )}
      {!isFetching && orders?.length > 0 && (
        <Grid columns={1} sx={{ rowGap: 3 }}>
          {orders.map((order) => (
            <AccountDashboardClubPastShipment
              key={order.number}
              order={order}
            />
          ))}
        </Grid>
      )}
      {!isFetching && orders && orders.length === 0 && (
        <Text as="p" variant="body1" m={0}>
          You haven't placed any club orders. Looking for your{' '}
          <Link to="/account/orders">order history</Link>?
        </Text>
      )}
    </Box>
  )
}

export default AccountDashboardClubPastShipments
