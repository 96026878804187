/** @jsx jsx */
import { useState } from 'react'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import moment from 'moment'
import { Box, Button, Flex, Heading, jsx, Spinner, Text } from 'theme-ui'
import { useSubscription } from '@chordcommerce/gatsby-theme-autonomy'
import VisuallyHidden from '~/components/Generic/VisuallyHidden'
import { useAria } from '~/hooks/components/use-aria'
import { toUsdCurrency } from '~/utils/formatters'
import {
  getFormattedBottleCount,
  getProductContentFromSku,
  getProductVariantFromSku,
} from '~/utils/products'
import {
  getIsSubscriptionCustomizable,
  getClubDiscountedPrice,
  getSubscriptionContentFromTags,
} from '~/utils/subscriptions'

const AccountDashboardClubUpcomingShipmentSkip = ({
  isLoading,
  setIsSkipping,
  skipSubscription,
}) => {
  const { updateAriaLive } = useAria()

  const handleSkip = async () => {
    await skipSubscription()
    setIsSkipping(false)
    updateAriaLive('You have skipped the next shipment.')
  }

  return (
    <Box bg="white" py={3} px={[2, 3]} sx={{ borderRadius: '10px' }}>
      <Text as="p" variant="title5" m={0} mb={1}>
        {'Are you sure you want to skip this shipment?'}
      </Text>
      <Text as="p" variant="body3" m={0}>
        {'You can always contact us if you change your mind.'}
      </Text>
      <Flex
        mt={3}
        sx={{
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="primaryRounded"
          mb={[1, 0]}
          onClick={() => handleSkip()}
          sx={{ minWidth: '267px', order: [null, '1'] }}
        >
          {!isLoading && 'Yes, skip my next shipment'}
          {isLoading && (
            <Spinner
              size="15"
              color="inherit"
              title="Submitting"
              sx={{
                '@media (prefers-reduced-motion)': {
                  circle: {
                    animationIterationCount: 8,
                  },
                },
              }}
            />
          )}
        </Button>
        <Button
          variant="reset"
          onClick={() => setIsSkipping(false)}
          sx={{ order: [null, '0'] }}
        >
          <Text as="span" variant="textLink">
            {'Cancel'}
          </Text>
        </Button>
      </Flex>
    </Box>
  )
}

const AccountDashboardClubUpcomingShipment = ({
  allProductContent,
  allSubscriptionContent,
  subscriptionId,
}) => {
  const { isLoading, skipSubscription, subscription } = useSubscription({
    subscriptionId,
  })
  const canCustomize = getIsSubscriptionCustomizable(subscription)
  const { actionableDate, lineItems, shipAddress } = subscription
  const [isSkipping, setIsSkipping] = useState(false)
  const displayLineItems = []
  let displayAddress = ''
  let quantity = 0
  let regularPrice = 0
  let discountPrice = 0
  let description = ''

  if (isSkipping) {
    return (
      <AccountDashboardClubUpcomingShipmentSkip
        isLoading={isLoading}
        setIsSkipping={setIsSkipping}
        skipSubscription={skipSubscription}
      />
    )
  }

  const actionableDateMoment = moment(actionableDate)
  const displayDate =
    moment().format('YYYY') === actionableDateMoment.format('YYYY')
      ? actionableDateMoment.format('MMMM D')
      : actionableDateMoment.format('MMMM D, YYYY')

  // The line items contain limited information, so pull the appropriate info
  // from the product and variant, which are found via the line item's sku.
  for (let i = 0; i < lineItems.length; i++) {
    const lineItem = lineItems[i]
    const sku = lineItem.sku
    const product = getProductContentFromSku(sku, allProductContent)
    const displayLineItem = {
      name: lineItem.sku,
      quantity: lineItem.quantity,
      sku,
    }

    if (product) {
      const variant = getProductVariantFromSku(sku, product)

      if (variant) {
        regularPrice = regularPrice + variant.price * lineItem.quantity

        displayLineItem.description = variant.clubDescription
        displayLineItem.image = variant.mainImage
      }

      displayLineItem.name = product.name
    }

    quantity = quantity + lineItem.quantity
    displayLineItems.push(displayLineItem)
  }

  // Find the discounted price and subscription description via the Contentful
  // subscription content.
  if (quantity > 0) {
    const subscripitionContent = getSubscriptionContentFromTags(
      subscription,
      allSubscriptionContent
    )

    if (subscripitionContent) {
      const { discountPercentage } = subscripitionContent
      discountPrice = getClubDiscountedPrice(discountPercentage, regularPrice)
      description = subscripitionContent.defaultVariantsDescription
    }
  }

  // Construct the one-line address.
  if (shipAddress) {
    const { address1, address2, city, stateText, zipcode } = shipAddress

    displayAddress = address1

    if (address2) {
      displayAddress = `${displayAddress} ${address2}`
    }

    displayAddress = `${displayAddress}, ${city}, ${stateText} ${zipcode}`
  }

  return (
    <Box bg="white" py={3} px={[2, 3]} sx={{ borderRadius: '10px' }}>
      <VisuallyHidden>
        <Heading as="h4" mb={3}>
          Upcoming shipment
        </Heading>
      </VisuallyHidden>
      <Flex
        sx={{
          alignItems: ['flex-start', 'center'],
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Box mb={[2, 0]} sx={{ flexGrow: '1' }}>
          <Text as="h5" variant="subtitle2" m={0} mb={0}>
            {'Ship date:'}
          </Text>
          <Heading as="p" variant="title3" mb={2}>
            {displayDate}
          </Heading>
          {displayAddress && (
            <Box>
              <Text as="h5" variant="subtitle2" m={0} mb={0}>
                {'Ship address:'}
              </Text>
              <Text as="p" variant="body3" m={0}>
                {displayAddress}
              </Text>
            </Box>
          )}
        </Box>
        <Box
          ml={[null, 2]}
          sx={{
            flexShrink: '0',
            textAlign: [null, 'right'],
            width: ['100%', 'auto'],
          }}
        >
          {canCustomize && (
            <Box mb={[2, 1]}>
              <Button
                variant="primaryRounded"
                sx={{ width: ['100%', 'auto'] }}
                aria-label="Customize this shipment"
                onClick={() =>
                  navigate(`/account/club/customize/${subscriptionId}/`)
                }
              >
                {'Customize'}
              </Button>
            </Box>
          )}
          <Button variant="reset" onClick={() => setIsSkipping(true)}>
            <Text
              as="span"
              variant="description"
              sx={{
                color: 'brandPrimary',
                textDecoration: 'underline',
              }}
            >
              {'Skip this shipment'}
            </Text>
          </Button>
        </Box>
      </Flex>
      {canCustomize && quantity > 0 && discountPrice > 0 && (
        <Flex
          mt={3}
          py={2}
          sx={{
            borderBottom: '1px solid',
            borderBottomColor: 'greyLight',
            borderTop: '1px solid',
            borderTopColor: 'greyLight',
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
          }}
        >
          <Text as="p" variant="body2" m={0}>
            <Text as="span" sx={{ fontWeight: 'bold' }}>
              {`${quantity} cans`}
            </Text>
            <Text as="span">
              {` (${getFormattedBottleCount(quantity)} bottle equivalent):`}
            </Text>
          </Text>
          <Text as="p" variant="body2" m={0}>
            <Text
              as="span"
              sx={{ color: 'greyDark', textDecoration: 'line-through' }}
            >
              {toUsdCurrency(regularPrice)}
            </Text>
            <Text as="span" sx={{ fontWeight: 'bold' }}>
              {` ${toUsdCurrency(discountPrice)}`}
            </Text>
          </Text>
        </Flex>
      )}
      {canCustomize &&
        displayLineItems &&
        displayLineItems.map((lineItem) => {
          const { description, image, name, quantity, sku } = lineItem

          return (
            <Flex
              key={`${subscription.id}-${sku}`}
              mt={2}
              sx={{ alignItems: ['flex-start', 'center'] }}
            >
              <Box
                mr={2}
                sx={{
                  flexBasis: ['80px', '104px'],
                  flexGrow: '0',
                  flexShrink: '0',
                }}
              >
                {image && (
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.description}
                  />
                )}
              </Box>
              <Box sx={{ flexGrow: '1' }}>
                <Text as="h5" variant="title6" m={0} mb="4px">
                  {`${name} (${quantity} cans)`}
                </Text>
                {description && (
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: description.childMarkdownRemark.html,
                    }}
                    variant="description"
                    sx={{ color: 'greyDark', p: { m: 0 } }}
                  />
                )}
              </Box>
            </Flex>
          )
        })}
      {canCustomize && description && (
        <Box
          mt={2}
          pt={2}
          sx={{ borderTop: '1px solid', borderTopColor: 'greyLight' }}
        >
          <Heading as="h4" variant="body2" mb={1}>
            {'Maker note:'}
          </Heading>
          <Text
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
            variant="body3"
            sx={{
              p: {
                '&:first-of-type': {
                  mt: 0,
                },
                '&:last-of-type': {
                  mb: 0,
                },
              },
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default AccountDashboardClubUpcomingShipment
