/** @jsx jsx */
import { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import moment from 'moment'
import { Box, Button, Heading, jsx, Text } from 'theme-ui'
import { useSubscription } from '@chordcommerce/gatsby-theme-autonomy'
import { getSubscriptionContentFromTags } from '~/utils/subscriptions'
import AccountDashboardAccountSubscriptionCancelForm from './SubscriptionCancelForm'
import AccountDashboardAccountSubscriptionPayment from './SubscriptionPayment'
import AccountDashboardAccountSubscriptionPreferences from './SubscriptionPreferences'
import AccountDashboardAccountSubscriptionShipping from './SubscriptionShipping'

const AccountDashboardAccountSubscriptionCard = ({
  allSubscriptionContent,
  email,
  subscriptionId,
}) => {
  const [isCanceling, setIsCanceling] = useState(false)

  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK_KEY)

  const {
    cancelSubscription,
    loadSubscription,
    subscription,
    updateSubscription,
  } = useSubscription({
    subscriptionId,
  })
  const { actionableDate, id, shipAddress } = subscription

  const subscriptionContent = getSubscriptionContentFromTags(
    subscription,
    allSubscriptionContent
  )

  return (
    <Box bg="white" py={3} px={[2, 3]} mt={2}>
      {!isCanceling && (
        <Box>
          {subscriptionContent && (
            <Heading as="h4" variant="title4" mb="4px">
              {subscriptionContent.name}
            </Heading>
          )}
          <Text as="p" variant="body2" m={0}>
            {`Next shipment: ${moment(actionableDate).format('MMMM D, Y')}`}
          </Text>
          <Elements stripe={stripePromise}>
            {shipAddress && (
              <Box
                mt={2}
                pt={2}
                sx={{
                  borderTop: '1px solid',
                  borderTopColor: 'greyLight',
                  position: 'relative',
                }}
              >
                <AccountDashboardAccountSubscriptionShipping
                  subscription={subscription}
                  updateSubscription={updateSubscription}
                />
              </Box>
            )}
            <Box
              mt={2}
              pt={2}
              sx={{
                borderTop: '1px solid',
                borderTopColor: 'greyLight',
                position: 'relative',
              }}
            >
              <AccountDashboardAccountSubscriptionPayment
                subscription={subscription}
                updateSubscription={updateSubscription}
              />
            </Box>
          </Elements>
          <Box
            mt={2}
            pt={2}
            sx={{
              borderTop: '1px solid',
              borderTopColor: 'greyLight',
              position: 'relative',
            }}
          >
            <AccountDashboardAccountSubscriptionPreferences
              loadSubscription={loadSubscription}
              subscription={subscription}
              subscriptionContent={subscriptionContent}
            />
          </Box>
          {false && (
            <Box
              mt={3}
              pt={2}
              sx={{ borderTop: '1px solid', borderTopColor: 'greyLight' }}
            >
              <Button variant="reset" onClick={() => setIsCanceling(true)}>
                <Text
                  as="span"
                  variant="description"
                  sx={{ color: 'brandPrimary', textDecoration: 'underline' }}
                >
                  Cancel this membership
                </Text>
              </Button>
            </Box>
          )}
        </Box>
      )}
      {isCanceling && (
        <AccountDashboardAccountSubscriptionCancelForm
          email={email}
          id={id}
          setIsCanceling={setIsCanceling}
          cancelSubscription={cancelSubscription}
        />
      )}
    </Box>
  )
}

export default AccountDashboardAccountSubscriptionCard
