/** @jsx jsx */
import React from 'react'
import { Box, jsx } from 'theme-ui'
import FormLabel from '~/components/Generic/Form/Label'
import { CardElement } from '@stripe/react-stripe-js'

// See examples within the codebase for how to use (wrapping with <Elements />,
// handling submission, etc.).
const FormStripeCard = React.forwardRef(
  ({ label, name, required, ...props }, ref) => {
    return (
      <Box
        sx={{
          '.StripeElement': {
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: 'greyMedium',
            borderRadius: 0,
            minHeight: '52px',
            width: '100%',
            padding: '15px 14px',
          },
        }}
      >
        <FormLabel name={name} label={label} required={required} />
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '16px',
                color: '#11172B',
                '::placeholder': {
                  color: '#D0D3D8',
                },
              },
              invalid: {
                color: '#DD0000',
              },
            },
          }}
        />
      </Box>
    )
  }
)
export default FormStripeCard
