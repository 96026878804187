/** @jsx jsx */
import { Link, navigate } from 'gatsby'
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  jsx,
  Text,
} from 'theme-ui'
import AccountDashboardAccountPanel from '~/components/Account/Panels/Account'
import AccountDashboardClubPanel from '~/components/Account/Panels/Club'
import AccountDashboardCommunityPanel from '~/components/Account/Panels/Community'
import AccountDashboardOrdersPanel from '~/components/Account/Panels/Orders'
import AccountDashboardReferralPanel from '~/components/Account/Panels/Referral'
import Dropdown from '~/components/Generic/Dropdown'
import VisuallyHidden from '~/components/Generic/VisuallyHidden'

const AccountDashboardPage = ({ path, ...rest }) => {
  const panels = [
    {
      title: 'Can Club',
      slug: 'club',
      component: <AccountDashboardClubPanel {...rest} />,
    },
    {
      title: 'Order history',
      slug: 'orders',
      component: <AccountDashboardOrdersPanel />,
    },
    {
      title: 'Maker community',
      slug: 'community',
      component: <AccountDashboardCommunityPanel />,
    },
    {
      title: 'Refer a friend, get wine',
      slug: 'referrals',
      component: <AccountDashboardReferralPanel />,
    },
    {
      title: 'Account settings',
      slug: 'account',
      component: <AccountDashboardAccountPanel {...rest} />,
    },
  ]
  const activePanel = panels.find(
    (panel) => panel.slug === path.replace('/', '')
  )

  return (
    <Box>
      <Container>
        <Grid columns={[1, null, 12]} sx={{ rowGap: '0' }}>
          <Grid
            bg="white"
            columns={[1, 2, 1]}
            ml={[-2, -3]}
            mr={[-2, -3, 0]}
            py={[2, null, 5]}
            px={[2, 3, 0]}
            sx={{
              alignItems: ['center', null, 'start'],
              display: [null, null, 'block'],
              gridColumn: [null, null, '1 / 5', '1 / 4'],
              position: 'relative',
              rowGap: '0',
              '&:after': {
                backgroundColor: 'white',
                bottom: '0',
                content: [null, null, null, '""'],
                display: [null, null, null, 'block'],
                left: '-500px',
                position: 'absolute',
                top: '0',
                width: '500px',
              },
            }}
          >
            <Flex
              mb={[2, 0, 3]}
              px={[0, 0, 3]}
              sx={{
                alignItems: ['center', 'flex-start'],
                flexDirection: [null, 'column'],
                justifyContent: 'space-between',
              }}
            >
              <Heading as="h1" variant="title3">
                {'Welcome!'}
              </Heading>
            </Flex>
            <Box sx={{ display: [null, null, 'none'] }}>
              <VisuallyHidden>
                <Heading as="h2" variant="title4" mb={1}>
                  {'Account navigation'}
                </Heading>
              </VisuallyHidden>
              <Dropdown
                ariaLabel="Account navigation"
                currentOption={activePanel}
                options={panels}
                optionsLabel="Select page"
                setOption={(option) => navigate(`/account/${option.slug}`)}
              />
            </Box>
            <Box sx={{ display: ['none', null, 'block'] }}>
              <VisuallyHidden>
                <Heading as="h2" variant="title4" mb={1}>
                  {'Account navigation'}
                </Heading>
              </VisuallyHidden>
              {panels.map(({ slug, title }, index) => {
                const { slug: activeSlug } = activePanel

                return (
                  <Box key={`panel-button-${index}`}>
                    <Button
                      as={Link}
                      to={`/account/${slug}`}
                      variant="reset"
                      mb={1}
                      px={[2, 3]}
                      sx={{
                        height: '40px',
                        position: 'relative',
                        textAlign: 'left',
                        width: '100%',
                        '&:before': {
                          backgroundColor: 'brandTertiary',
                          bottom: '0',
                          content: '""',
                          display: slug === activeSlug ? 'block' : 'none',
                          left: '0',
                          position: 'absolute',
                          top: '0',
                          width: '8px',
                        },
                      }}
                    >
                      <Text as="span" variant="title6">
                        {title}
                      </Text>
                    </Button>
                  </Box>
                )
              })}
            </Box>
          </Grid>
          <Box
            pt={[3, 4, 5]}
            pb={14}
            sx={{ gridColumn: [null, null, '5 / -1', '4 / -1'] }}
          >
            {activePanel &&
              activePanel.hasOwnProperty('component') &&
              activePanel.component}
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default AccountDashboardPage
