/** @jsx jsx */
import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Box, jsx, Spinner } from 'theme-ui'
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy'
import { useAria } from '~/hooks/components/use-aria'

const AccountLogoutPage = () => {
  const { updateAriaLive } = useAria()
  const { logout } = useAuth()

  useEffect(() => {
    const onLogout = async () => {
      try {
        await logout()
        updateAriaLive('You have logged out.')
        navigate('/account/login')
      } catch (error) {}
    }

    onLogout()
  }, [logout, updateAriaLive])

  return (
    <Box py={10} sx={{ textAlign: 'center' }}>
      <Spinner
        color="brandTertiary"
        size="100"
        title="Logging out"
        sx={{
          '@media (prefers-reduced-motion)': {
            circle: {
              animationIterationCount: 8,
            },
          },
        }}
      />
    </Box>
  )
}

export default AccountLogoutPage
