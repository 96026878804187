/** @jsx jsx */
import React from 'react'
import { Box, jsx, Select } from 'theme-ui'
import FormLabel from '~/components/Generic/Form/Label'

const FormSelect = React.forwardRef(
  ({ children, label, name, required, ...props }, ref) => {
    return (
      <Box>
        <FormLabel name={name} label={label} required={required} />
        <Select
          sx={{
            variant: 'text.body2',
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: 'greyMedium',
            borderRadius: 0,
            color: 'black',
            lineHeight: '28px',
            width: '100%',
            padding: '11px 14px',
          }}
          name={name}
          ref={ref}
          {...props}
        >
          {children}
        </Select>
      </Box>
    )
  }
)
export default FormSelect
