/** @jsx jsx */
import { Fragment, useRef } from 'react'
import { Box, Button, Text, jsx } from 'theme-ui'
import useOnClickOutside from '~/hooks/components/use-on-click-outside'
import DropdownOption from '~/components/Generic/Dropdown/Option'
import CheckmarkIcon from '~/assets/images/icons/checkmark.inline.svg'

const DropdownOptions = ({
  currentOption,
  handleSelectedOption,
  options,
  optionsLabel,
}) => {
  const optionsWrapperRef = useRef()

  useOnClickOutside(optionsWrapperRef, () =>
    handleSelectedOption(currentOption)
  )

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          display: ['block', 'none'],
          height: '100%',
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: '999',
        }}
      />
      <Box
        ref={optionsWrapperRef}
        sx={{
          backgroundColor: 'white',
          borderColor: [null, 'greyDark'],
          borderRadius: ['18px 18px 0 0', '18px'],
          borderStyle: [null, 'solid'],
          borderWidth: [null, '1px'],
          bottom: [0, 'auto'],
          left: '0',
          overflow: 'hidden',
          position: ['fixed', 'absolute'],
          top: [null, 0],
          width: '100%',
          zIndex: '1000',
        }}
      >
        <Box
          py={2}
          sx={{
            backgroundColor: 'backgroundMedium',
            display: [null, 'none'],
            textAlign: 'center',
          }}
        >
          <Text
            as="p"
            sx={{
              fontSize: '20px',
              fontWeight: 'medium',
              letterSpacing: '0.5px',
              lineHeight: '32px',
            }}
          >
            {`${optionsLabel}:`}
          </Text>
        </Box>
        <Box>
          {options.map((option, index) => (
            <Button
              variant="reset"
              key={`option-${index}`}
              onClick={(e) => handleSelectedOption(option)}
              sx={{
                backgroundColor: 'white',
                cursor: 'pointer',
                display: 'block',
                height: ['62px', '48px'],
                padding: ['14px 48px 19px 24px', '7px 48px 12px 24px'],
                position: 'relative',
                textAlign: ['center', 'left'],
                width: '100%',
                '&:hover': {
                  backgroundColor: [null, 'rgba(226, 224, 223, 0.3)'],
                },
                '&:active': {
                  backgroundColor: 'greyLight',
                },
                '&:focus-visible': {
                  backgroundColor: 'rgba(226, 224, 223, 0.3)',
                  outline: 'none',
                },
              }}
            >
              <DropdownOption option={option} />
              {option.title === currentOption.title && (
                <CheckmarkIcon
                  sx={{
                    color: 'brandTertiary',
                    position: 'absolute',
                    right: '24px',
                    top: 'calc(50% - 6px)',
                  }}
                />
              )}
              <Box
                sx={{
                  borderBottomColor: 'greyLight',
                  borderBottomStyle: ['solid', 'none'],
                  borderBottomWidth: ['1px', 0],
                  bottom: 0,
                  left: '24px',
                  position: 'absolute',
                  right: '24px',
                }}
              />
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            display: [null, 'none'],
            height: '20vh',
          }}
        />
      </Box>
    </Fragment>
  )
}

export default DropdownOptions
