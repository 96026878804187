export const getKitBottleCount = (kit) => getKitCanCount(kit) / 3

export const getKitCanCount = (kit) =>
  kit?.products.filter(
    (product) =>
      product?.variants?.[0]?.optionValues?.[0]?.slug === 'quantity-1'
  ).length

export const getUniqueKitProducts = (kit) =>
  kit.products.reduce(
    (uniqueProducts, product) =>
      !uniqueProducts.find(
        (uniqueProduct) => product.slug === uniqueProduct.slug
      )
        ? [...uniqueProducts, product]
        : uniqueProducts,
    []
  )
