/** @jsx jsx */
import { Box, jsx, Spinner } from 'theme-ui'
import { useSubscriptions } from '@chordcommerce/gatsby-theme-autonomy'
import AccountDashboardClubEmptyState from './EmptyState'
import AccountDashboardClubPastShipments from './PastShipments'
import AccountDashboardClubUpcomingShipments from './UpcomingShipments'
import { sortActiveSubscriptions } from '~/utils/subscriptions'

const AccountDashboardClubPanel = ({
  allProductContent,
  allSubscriptionContent,
}) => {
  const { isLoaded, subscriptions } = useSubscriptions()
  const activeSubscriptions = sortActiveSubscriptions(subscriptions)

  if (!isLoaded) {
    return (
      <Box py={10} sx={{ textAlign: 'center' }}>
        <Spinner
          color="brandTertiary"
          size="100"
          sx={{
            '@media (prefers-reduced-motion)': {
              circle: {
                animationIterationCount: 8,
              },
            },
          }}
        />
      </Box>
    )
  }

  if (activeSubscriptions.length === 0) {
    return (
      <AccountDashboardClubEmptyState
        allProductContent={allProductContent}
        allSubscriptionContent={allSubscriptionContent}
      />
    )
  }

  return (
    <Box>
      <Box>
        <AccountDashboardClubUpcomingShipments
          allProductContent={allProductContent}
          allSubscriptionContent={allSubscriptionContent}
          subscriptionIds={activeSubscriptions.map(({ id }) => id)}
        />
      </Box>
      <Box mt={[5, 6]}>
        <AccountDashboardClubPastShipments />
      </Box>
    </Box>
  )
}

export default AccountDashboardClubPanel
