/** @jsx jsx */
import { Fragment, useState } from 'react'
import { Button, Heading, jsx, Text } from 'theme-ui'
import AccountDashboardAccountSubscriptionAddressForm from './SubscriptionAddressForm'

const AccountDashboardAccountShipping = ({
  subscription,
  updateSubscription,
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const { shipAddress } = subscription

  return (
    <Fragment>
      {!isEditing && (
        <Button
          variant="reset"
          aria-label="Edit shipping address"
          onClick={() => setIsEditing(true)}
          sx={{
            position: 'absolute',
            right: '0',
            top: '12px',
          }}
        >
          <Text
            as="span"
            variant="description"
            sx={{ color: 'brandPrimary', textDecoration: 'underline' }}
          >
            Edit
          </Text>
        </Button>
      )}
      <Heading as="h5" variant="title5" mb="4px">
        {!isEditing && 'Club shipping address'}
        {isEditing && 'Edit club shipping address'}
      </Heading>
      {!isEditing && (
        <Text as="p" variant="body2" m={0}>
          {shipAddress.name}
          <br />
          {shipAddress.address1}
          {shipAddress.address2 && ` ${shipAddress.address2}`}
          <br />
          {`${shipAddress.city}, ${shipAddress.stateText} ${shipAddress.zipcode}`}
        </Text>
      )}
      {isEditing && (
        <AccountDashboardAccountSubscriptionAddressForm
          addressType="shipAddress"
          setIsEditing={setIsEditing}
          subscription={subscription}
          updateSubscription={updateSubscription}
        />
      )}
    </Fragment>
  )
}

export default AccountDashboardAccountShipping
