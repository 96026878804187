/** @jsx jsx */
import { Fragment } from 'react'
import moment from 'moment'
import { Box, Button, Flex, Grid, Heading, jsx, Text } from 'theme-ui'
import VisuallyHidden from '~/components/Generic/VisuallyHidden'

const AccountDashboardOrdersOrderCard = ({ order }) => {
  const {
    completedAt,
    displayTotal,
    lineItems,
    number,
    shipAddress,
    shipments,
  } = order

  return (
    <Box bg="white" mt={3} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
      <VisuallyHidden>
        <Heading as="h4" variant="title5" mt={2}>
          {'Order details'}
        </Heading>
      </VisuallyHidden>
      <Box bg="backgroundMedium" px={[2, 3]} py={2}>
        <Flex mb={1} sx={{ justifyContent: 'space-between' }}>
          <Text as="p" variant="title6" m={0}>
            <VisuallyHidden>
              <Text as="span">{'Order date: '}</Text>
            </VisuallyHidden>
            <Text as="span">{moment(completedAt).format('MMMM D, YYYY')}</Text>
          </Text>
          <Text as="p" variant="title6" m={0}>
            <VisuallyHidden>
              <Text as="span">{'Order total: '}</Text>
            </VisuallyHidden>
            <Text as="span">{displayTotal}</Text>
          </Text>
        </Flex>
        <Text as="p" variant="title6" m={0}>
          <Text as="span">{'Order #: '}</Text>
          <Text as="span" sx={{ fontWeight: 'normal' }}>
            {number}
          </Text>
        </Text>
      </Box>
      <Box px={[2, 3]} py={2}>
        {shipAddress && (
          <Box
            pb={[2, 3]}
            sx={{
              borderBottom: '1px solid',
              borderBottomColor: 'greyLight',
            }}
          >
            <Flex sx={{ flexDirection: ['column', 'row'] }}>
              <Box sx={{ flexGrow: '1', order: ['1', '0'] }}>
                <Text
                  as="p"
                  variant="body2"
                  m={0}
                  mb={1}
                  sx={{ fontWeight: 'bold' }}
                >
                  {'Shipping to:'}
                </Text>
                <Text as="p" variant="body2" m={0}>
                  <Text as="span">{shipAddress.address1}</Text>
                  {shipAddress.address2 && (
                    <Text as="span"> {shipAddress.address2}</Text>
                  )}
                </Text>
                <Text
                  as="p"
                  variant="body2"
                  m={0}
                >{`${shipAddress.city}, ${shipAddress.stateText} ${shipAddress.zipcode}`}</Text>
              </Box>
              {shipments?.[0]?.shippedAt && (
                <Box
                  mb={[2, 0]}
                  sx={{
                    order: ['0', '1'],
                    textAlign: ['center', 'right'],
                  }}
                >
                  <Text as="p" m={0} mb={1}>
                    <Text
                      as="span"
                      variant="title6"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {'Shipped: '}
                    </Text>
                    <Text as="span" variant="body2">
                      {moment(shipments[0].shippedAt).format('MMMM D, YYYY')}
                    </Text>
                  </Text>
                  {shipments[0].externalTrackingUrl && (
                    <Button
                      as="a"
                      variant="primaryRounded"
                      href={shipments[0].externalTrackingUrl}
                      target="_blank"
                      sx={{ width: ['100%', 'auto'] }}
                    >
                      {'Track shipment'}
                    </Button>
                  )}
                </Box>
              )}
            </Flex>
          </Box>
        )}
        <VisuallyHidden>
          <Heading as="h5" variant="title5" mt={2}>
            {'Order line items'}
          </Heading>
        </VisuallyHidden>
        {lineItems && lineItems.length && (
          <Grid columns={1} pt={[2, 3]} sx={{ rowGap: 1 }}>
            {lineItems.map(({ quantity, variant }) => {
              const { hidden, name, optionsText, sku } = variant
              let size = ''
              if (optionsText.indexOf('Cans') !== -1) {
                size = optionsText.replace('Cans: ', '')
                size = `${size} can${size > 1 ? 's' : ''}`
              }

              if (hidden) {
                return <Fragment key={`${number}-${sku}`} />
              }

              return (
                <Flex
                  sx={{ flexDirection: ['column', 'row'] }}
                  key={`${number}-${sku}`}
                >
                  <Text variant="title6" sx={{ flexGrow: '1' }}>
                    {name}
                  </Text>
                  <Text
                    variant="body2"
                    sx={{
                      textAlign: [null, 'right'],
                      width: '96px',
                    }}
                  >
                    {size}
                  </Text>
                  <Text
                    variant="body2"
                    sx={{
                      textAlign: [null, 'right'],
                      width: '112px',
                    }}
                  >{`QTY: ${quantity}`}</Text>
                </Flex>
              )
            })}
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default AccountDashboardOrdersOrderCard
