import React from 'react'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import DashboardPage from '~/components/Account/Page'
import LogoutPage from '~/components/Auth/Logout/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import PrivateRoute from '~/components/Auth/PrivateRoute'

// https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/#handling-client-only-routes-with-gatsby
const AccountPages = ({ data }) => {
  return (
    <Layout>
      <Metadata
        title="Account"
        description="Manage your Maker Wine account."
        noindex={true}
      />
      <Router basepath="/account">
        <PrivateRoute
          path="/club"
          component={DashboardPage}
          allProductContent={data.allProductContent.nodes}
          allSubscriptionContent={data.allSubscriptionContent.nodes}
        />
        <PrivateRoute path="/orders" component={DashboardPage} />
        <PrivateRoute path="/community" component={DashboardPage} />
        <PrivateRoute path="/referrals" component={DashboardPage} />
        <PrivateRoute
          path="/account"
          component={DashboardPage}
          allSubscriptionContent={data.allSubscriptionContent.nodes}
        />
        <PrivateRoute path="/logout" component={LogoutPage} />
      </Router>
    </Layout>
  )
}

export const query = graphql`
  query AccountPages {
    allProductContent: allContentfulProduct {
      distinct(field: contentful_id)
      nodes {
        ...ProductClubFragment
      }
    }
    allSubscriptionContent: allContentfulSubscription {
      distinct(field: contentful_id)
      nodes {
        ...SubscriptionFragment
      }
    }
  }
`

export default AccountPages
