/** @jsx jsx */
import { Fragment, useState } from 'react'
import { Box, Button, Heading, jsx, Text } from 'theme-ui'
import AccountDashboardAccountSubscriptionPreferenceForm from './SubscriptionPreferenceForm'
import {
  SUBSCRIPTION_QUANTITY_OPTIONS,
  SUBSCRIPTION_TYPE_OPTIONS,
} from '~/utils/subscriptions'

const AccountDashboardAccountPreferences = ({
  loadSubscription,
  subscription,
  subscriptionContent,
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const { tagList } = subscription
  const { defaultQuantity } = subscriptionContent

  const typeOptions = SUBSCRIPTION_TYPE_OPTIONS
  const currentType =
    typeOptions.find((option) => tagList.includes(option.tag)) || typeOptions[0]

  const quantityOptions = SUBSCRIPTION_QUANTITY_OPTIONS
  const currentQuantity = quantityOptions.find(
    (option) => parseInt(option.key) === defaultQuantity
  )

  return (
    <Fragment>
      {!isEditing && (
        <Button
          variant="reset"
          aria-label="Edit club preferences"
          onClick={() => setIsEditing(true)}
          sx={{
            position: 'absolute',
            right: '0',
            top: '12px',
          }}
        >
          <Text
            as="span"
            variant="description"
            sx={{ color: 'brandPrimary', textDecoration: 'underline' }}
          >
            Edit
          </Text>
        </Button>
      )}
      <Heading as="h5" variant="title5" mb="4px">
        {!isEditing && 'Club preferences'}
        {isEditing && 'Edit club preferences'}
      </Heading>
      {!isEditing && (
        <Box>
          <Text as="p" variant="body3" mt={2} mb={1}>
            Want our curated selection to only include red wine? No red wine?
            More or less cans? Specify that here! You'll always be able to
            customize each shipment regardless.
          </Text>
          <Text as="p" variant="body2" m={0}>
            <Text as="strong">{'Varietals: '} </Text>
            {currentType.label}
          </Text>
          <Text as="p" variant="body2" m={0}>
            <Text as="strong">{'Quantity: '} </Text>
            {currentQuantity.label}
          </Text>
        </Box>
      )}
      {isEditing && (
        <AccountDashboardAccountSubscriptionPreferenceForm
          loadSubscription={loadSubscription}
          setIsEditing={setIsEditing}
          subscription={subscription}
          currentType={currentType}
          typeOptions={typeOptions}
          currentQuantity={currentQuantity}
          quantityOptions={quantityOptions}
        />
      )}
    </Fragment>
  )
}

export default AccountDashboardAccountPreferences
