/** @jsx jsx */
import { Box, Heading, jsx, Text } from 'theme-ui'
import { useSubscription } from '@chordcommerce/gatsby-theme-autonomy'
import {
  getIsSubscriptionCustomizable,
  getSubscriptionCustomizableEndDate,
  getSubscriptionCustomizableStartDate,
} from '~/utils/subscriptions'

const AccountDashboardClubUpcomingMessage = ({ subscriptionId }) => {
  const { subscription } = useSubscription({ subscriptionId })
  const canCustomize = getIsSubscriptionCustomizable(subscription)
  const customizeStartDate = getSubscriptionCustomizableStartDate(subscription)
  const customizeEndDate = getSubscriptionCustomizableEndDate(subscription)

  return (
    <Box bg="backgroundMedium" mb={3} p={[2, 3]}>
      <Heading as="p" variant="title6" mb={1}>
        {canCustomize && 'Wine is coming your way soon!'}
        {!canCustomize && "We're working on your next picks!"}
      </Heading>
      <Text as="p" variant="body2">
        {canCustomize &&
          `Enjoy a box curated by us, or be sure to customize your order before ${customizeEndDate.format(
            'MMMM Do'
          )}.`}
        {!canCustomize &&
          `Come back on ${customizeStartDate.format(
            'MMMM Do'
          )} to view or customize your next shipment.`}
      </Text>
    </Box>
  )
}

export default AccountDashboardClubUpcomingMessage
