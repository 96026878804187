/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, Button, Grid, Heading, jsx, Text } from 'theme-ui'
import { useTranslate, useUser } from '@chordcommerce/gatsby-theme-autonomy'
import ReferralsForm from '~/components/Referrals/Form'
import useUiTextQuery from '~/hooks/graphql/queries/use-ui-text'
import { toUsdCurrency } from '~/utils/formatters'

const AccountDashboardReferralPanelForm = () => {
  const translate = useTranslate()
  const referralsUiText = useUiTextQuery('global', 'referrals')

  return (
    <Grid columns={[1, null, null, 9]}>
      <Box sx={{ gridColumn: [null, null, null, '1 / 8'] }}>
        <Heading as="h2" variant="title3" mb={2}>
          {translate('referral.title')}
        </Heading>
        <Text as="p" variant="body2" my={0}>
          {referralsUiText.account}
        </Text>
        <Box mt={3}>
          <ReferralsForm />
        </Box>
      </Box>
    </Grid>
  )
}

const AccountDashboardReferralPanelBalance = ({ user }) => {
  return (
    <Grid columns={[null, 12, 8, 9]}>
      <Box px={[2, 3]} sx={{ gridColumn: [null, '1 / 7', '1 / 5', '1 / 6'] }}>
        <Text as="p" m={0} color="greyDark" variant="subtitle2">
          Your credit balance:
        </Text>
        <Text as="p" mt={[1, 2]} mb={0} variant="title1">
          {toUsdCurrency(user?.data?.storeCredit || 0)}
        </Text>
      </Box>
      <Box
        pl={[2, 0]}
        pr={[2, 3]}
        sx={{ gridColumn: [null, '7 / -1', '5 / -1', '6 / -1'] }}
      >
        <Box
          px={[2, null, null, 3]}
          py={2}
          sx={{ backgroundColor: 'rgba(226, 224, 223, 0.25)' }}
        >
          <Text as="p" mt={0} mb="4px" variant="body3">
            This balance is automatically applied to your shopping cart.
          </Text>
          <Button as={Link} variant="reset" to="/shop/">
            <Text as="span" variant="textLink">
              Shop wines
            </Text>
          </Button>
        </Box>
      </Box>
    </Grid>
  )
}

const AccountDashboardReferralPanel = () => {
  const { user } = useUser()

  return (
    <Box>
      <Box bg="backgroundMedium" mt={[-3, -4, -5]} mx={[-2, -3]} py={5} px={3}>
        <AccountDashboardReferralPanelForm />
      </Box>
      <Box bg="white" mt={3} py={3}>
        <AccountDashboardReferralPanelBalance user={user} />
      </Box>
    </Box>
  )
}

export default AccountDashboardReferralPanel
