/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Box, Button, Flex, Grid, jsx, Spinner, Text } from 'theme-ui'
import { useUser } from '@chordcommerce/gatsby-theme-autonomy'
import FormInput from '~/components/Generic/Form/Input'
import FormSelect from '~/components/Generic/Form/Select'
import FormStripeCard from '~/components/Generic/Form/StripeCard'
import { useAria } from '~/hooks/components/use-aria'
import { trackClubPaymentMethodUpdated } from '~/services/analytics/client'
import { STATES_ALL, STATES_SHIPPABLE } from '~/utils/lists/us-states'

const AccountDashboardAccountSubscriptionAddressForm = ({
  addressType,
  setIsEditing,
  subscription,
  updateSubscription,
}) => {
  const { updateAriaLive } = useAria()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const { register, handleSubmit, errors } = useForm()

  const { user } = useUser()

  const stripe = useStripe()
  const elements = useElements()

  const address = subscription[addressType]
  const stateOptions =
    addressType === 'shipAddress' ? STATES_SHIPPABLE : STATES_ALL

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    setError(null)

    try {
      const payload = { [`${addressType}Attributes`]: data }

      if (addressType === 'billAddress') {
        if (!stripe || !elements) {
          return
        }

        const cardElement = elements.getElement(CardElement)
        // The payment method created here will not initially have a Stripe
        // customer (cus_*) associated with it. That is expected and a customer
        // will be created and associated during the next recurring charge. This
        // was confirmed with Chord.
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: data.name,
            address: {
              line1: data.address1,
              line2: data.address2,
              city: data.city,
              country: data.country_iso,
              state: data.state_name,
              postal_code: data.zipcode,
            },
          },
        })

        if (error) {
          console.error(error.message)
          setError(error.message)
          setIsSubmitting(false)
          return
        }

        const { card } = paymentMethod
        payload.paymentSourceAttributes = {
          month: card.exp_month,
          year: card.exp_year,
          ccType: card.brand,
          lastDigits: card.last4,
          name: paymentMethod.billing_details.name,
          gateway_payment_profile_id: paymentMethod.id,
        }

        await trackClubPaymentMethodUpdated({
          subscriptionId: subscription.id,
          user,
        })
      }

      await updateSubscription(payload)
    } catch (error) {
      console.error(error)
      setError(error.message)
    }

    setIsEditing(false)
    setIsSubmitting(false)
    updateAriaLive('Your changes were saved.')
  }

  return (
    <Box mt={2}>
      <form
        name={`edit-subscription-address`}
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        {addressType === 'billAddress' && (
          <Box mb={[2, 3]}>
            <FormStripeCard name="card" label="Card info" required={true} />
          </Box>
        )}
        <Box mb={[2, 3]}>
          <FormInput
            name="name"
            title={'Name'}
            label={'Name'}
            aria-label={'Name'}
            required={true}
            type="text"
            defaultValue={address?.name}
            error={
              errors && errors['name'] && errors['name'].type === 'required'
                ? 'This field is required.'
                : null
            }
            ref={register({
              required: true,
            })}
          />
        </Box>
        <Box mb={[2, 3]}>
          <FormInput
            name="address1"
            title={'Address 1'}
            label={'Address 1'}
            aria-label={'Address 1'}
            required={true}
            type="text"
            defaultValue={address?.address1}
            error={
              errors &&
              errors['address1'] &&
              errors['address1'].type === 'required'
                ? 'This field is required.'
                : null
            }
            ref={register({
              required: true,
            })}
          />
        </Box>
        <Box mb={[2, 3]}>
          <FormInput
            name="address2"
            title={'Address 2'}
            label={'Address 2'}
            aria-label={'Address 2'}
            type="text"
            defaultValue={address?.address2}
            ref={register()}
          />
        </Box>
        <Box mb={[2, 3]}>
          <FormInput
            name="city"
            title={'City'}
            label={'City'}
            aria-label={'City'}
            required={true}
            type="text"
            defaultValue={address?.city}
            error={
              errors && errors['city'] && errors['city'].type === 'required'
                ? 'This field is required.'
                : null
            }
            ref={register({
              required: true,
            })}
          />
        </Box>
        <Grid columns={[1, 2]} mb={[2, 3]} sx={{ rowGap: [2, 3] }}>
          <FormSelect
            name="state_name"
            title={'State'}
            label={'State'}
            aria-label={'State'}
            required={true}
            defaultValue={address?.state?.abbr}
            error={
              errors &&
              errors['state_name'] &&
              errors['state_name'].type === 'required'
                ? 'This field is required.'
                : null
            }
            ref={register({
              required: true,
            })}
          >
            {Object.keys(stateOptions).map((abbr) => (
              <option key={`state-${abbr}`} value={abbr}>
                {stateOptions[abbr]}
              </option>
            ))}
          </FormSelect>
          <FormInput
            name="zipcode"
            title={'ZIP'}
            label={'ZIP'}
            aria-label={'ZIP'}
            required={true}
            type="text"
            defaultValue={address?.zipcode}
            error={
              errors &&
              errors['zipcode'] &&
              errors['zipcode'].type === 'required'
                ? 'This field is required.'
                : null
            }
            ref={register({
              required: true,
            })}
          />
        </Grid>
        {addressType === 'shipAddress' && (
          <Text mb={[2, 1]} variant="description" sx={{ color: 'greyDark' }}>
            We can't ship to AK, AL, AR, DE, HI, MS, RI, or UT.
          </Text>
        )}
        <Box mb={[2, 3]} sx={{ display: 'none' }}>
          <FormInput
            name="country_iso"
            title={'Country'}
            label={'Country'}
            aria-label={'Country'}
            required={true}
            defaultValue={address?.country?.iso}
            error={
              errors &&
              errors['country_iso'] &&
              errors['country_iso'].type === 'required'
                ? 'This field is required.'
                : null
            }
            ref={register({
              required: true,
            })}
          />
        </Box>
        {error && (
          <Text as="p" variant="error">
            {error}
          </Text>
        )}
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="primaryRounded"
            type="submit"
            mb={1}
            sx={{ order: [null, '1'], width: ['100%', 'auto'] }}
          >
            {!isSubmitting && 'Save'}
            {isSubmitting && (
              <Spinner
                size="24"
                color="inherit"
                mb={-1}
                sx={{
                  position: 'relative',
                  top: '-2px',
                  '@media (prefers-reduced-motion)': {
                    circle: {
                      animationIterationCount: 8,
                    },
                  },
                }}
                title="Saving"
              />
            )}
          </Button>
          <Button
            variant="reset"
            type="button"
            aria-label="Cancel changes"
            onClick={() => setIsEditing(false)}
            sx={{ order: [null, '0'] }}
          >
            <Text
              as="span"
              variant="body3"
              sx={{ color: 'brandPrimary', textDecoration: 'underline' }}
            >
              Cancel
            </Text>
          </Button>
        </Flex>
      </form>
    </Box>
  )
}

export default AccountDashboardAccountSubscriptionAddressForm
