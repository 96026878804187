/** @jsx jsx */
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import moment from 'moment'
import { Box, Button, Flex, Grid, Heading, jsx, Text } from 'theme-ui'
import PackCallout from '~/components/Pack/Callout'
import { getPackUrl } from '~/utils/packs'

const AccountDashboardCommunityEventCard = ({ pack }) => {
  const { eventDate, mainImage, name, longDescription } = pack
  const formattedDate = eventDate && moment(eventDate).format('ddd, MMMM DD')
  const formattedTime = eventDate && moment(eventDate).format('h:mm a')
  const orderByDate =
    eventDate && moment(eventDate).subtract(4, 'days').format('MMMM DD')

  return (
    <Box bg="white" sx={{ borderRadius: '10px', overflow: 'hidden' }}>
      <PackCallout pack={pack} />
      <Flex
        p={[2, 3]}
        sx={{
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
          position: 'relative',
          '&:after': {
            borderBottom: '1px solid',
            borderBottomColor: 'greyLight',
            bottom: '0',
            content: '""',
            display: 'block',
            left: ['16px', '24px'],
            position: 'absolute',
            right: ['16px', '24px'],
          },
        }}
      >
        <Box mb={[2, 0]}>
          {formattedDate && (
            <Text as="h3" variant="title3" mb={[null, 1]}>
              {formattedDate}
            </Text>
          )}
          {formattedTime && (
            <Text as="p" variant="subtitle2" sx={{ color: 'greyDark' }}>
              {formattedTime}
            </Text>
          )}
        </Box>
        <Box sx={{ textAlign: [null, 'right'] }}>
          <Button as={Link} to={getPackUrl(pack)} variant="primaryRounded">
            {'Shop event wines'}
          </Button>
          {orderByDate && (
            <Text
              as="p"
              variant="description"
              mt={[1, 2]}
              mb={0}
              sx={{ color: 'greyDark' }}
            >
              {`Order by ${orderByDate} at 11pm PT`}
            </Text>
          )}
        </Box>
      </Flex>
      <Box p={[2, 3]} pb={3} px={[null, 0]}>
        <Grid columns={[1, 12, 8, 9]} sx={{ rowGap: 2 }}>
          <Box pl={[null, 3]} sx={{ gridColumn: [null, '1 / 7', '1 / 5'] }}>
            <GatsbyImage
              image={mainImage.gatsbyImageData}
              alt={mainImage.description}
            />
          </Box>
          <Box
            pr={[null, 3]}
            sx={{ alignSelf: 'center', gridColumn: [null, '7 / -1', '5 / -1'] }}
          >
            <Heading as="h4" variant="title4" mb={1}>
              {name}
            </Heading>
            {longDescription && (
              <Box
                dangerouslySetInnerHTML={{
                  __html: longDescription.childMarkdownRemark.html,
                }}
                sx={{
                  '& p': {
                    my: 0,
                    variant: 'text.body2',
                  },
                }}
              />
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default AccountDashboardCommunityEventCard
