import { getKitCanCount, getUniqueKitProducts } from '~/utils/kits'

export const getAvailablePackKits = (pack) =>
  pack?.kits?.filter((kit) => !kit.noIndividualPurchase) || []

export const getAvailablePackBulkKits = (pack) =>
  getAvailablePackKits(pack).filter((kit) => getKitCanCount(kit) === 24)

export const getPackUrl = (pack) => `/products/${pack.slugUrl || pack.slug}/`

export const getUniquePackProducts = (pack) => {
  const kits = getAvailablePackKits(pack)

  return getUniqueKitProducts(kits[0]) || []
}

export const getDiscountedPrice = (discountPercentage, price) => {
  return price * ((100 - discountPercentage) / 100)
}
