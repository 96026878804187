/** @jsx jsx */
import { Box, Flex, Text, jsx } from 'theme-ui'

const DropdownOption = ({ disabled, option }) => {
  const { additional, additionalStruck, subtitle, title } = option

  return (
    <Flex
      sx={{
        color: disabled ? 'greyDark' : 'black',
        justifyContent: 'space-between',
        lineHeight: '30px',
        textAlign: 'left',
        width: '100%',
      }}
    >
      <Text
        as="strong"
        sx={{
          fontSize: '18px',
          letterSpacing: '0.25px',
        }}
      >
        {title}
        {subtitle && (
          <Text
            as="span"
            pl={[1, '4px', 1]}
            sx={{
              color: 'greyDark',
              fontSize: '15px',
              letterSpacing: '0.25px',
              opacity: '0.9',
            }}
          >
            {subtitle}
          </Text>
        )}
      </Text>
      <Box>
        {additionalStruck && (
          <Text
            aria-hidden="true"
            as="strong"
            mr={1}
            sx={{
              fontSize: '18px',
              letterSpacing: '0.25px',
              textDecoration: 'line-through',
              // @todo Use a container query once supported by theme-ui.
              '@media only screen and (max-width: 379px)': {
                display: 'none',
              },
              '@media only screen and (min-width: 640px) and (max-width: 720px)':
                {
                  display: 'none',
                },
              '@media only screen and (min-width: 1024px) and (max-width: 1080px)':
                {
                  display: 'none',
                },
            }}
          >
            {additionalStruck}
          </Text>
        )}
        {additional && (
          <Text
            as="strong"
            sx={{
              color: additionalStruck ? 'brandTertiary' : 'black',
              fontSize: '18px',
              letterSpacing: '0.25px',
            }}
          >
            {additional}
          </Text>
        )}
      </Box>
    </Flex>
  )
}

export default DropdownOption
