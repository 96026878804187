/** @jsx jsx */
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, Heading, jsx, Spinner, Text } from 'theme-ui'
import { useTranslate, useUser } from '@chordcommerce/gatsby-theme-autonomy'
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel'
import { addUserToKlaviyoList } from '~/services/klaviyo/client'

const AccountDashboardCommunityTastingPanelForm = () => {
  const translate = useTranslate()
  const [loading, setLoading] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const { user } = useUser()

  const { register, handleSubmit, errors } = useForm()

  const onSubmit = async (data) => {
    const { email } = data

    setLoading(true)
    try {
      addUserToKlaviyoList(
        { $email: email },
        process.env.GATSBY_KLAVIYO_LIST_ID_TASTING_PANELS
      )
      setSubmitted(true)
    } catch (error) {
      console.error(error, {
        source: 'AccountDashboardCommunityTastingPanelForm',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      {!submitted && (
        <Fragment>
          <Heading as="h3" variant="title3" mb={1}>
            {'Tasting Panels'}
          </Heading>
          <Text as="p" variant="body2" m={0} mb={[3, 4]}>
            Drop your email if you’re interested in joining our Can Club blind
            tasting panel to help pick the next Maker wines.
          </Text>
          <Box as="form" data-testid="form" onSubmit={handleSubmit(onSubmit)}>
            <Flex
              sx={{
                flexDirection: ['column', 'row'],
                justifyContent: 'space-between',
              }}
            >
              <Box mr={[0, 2]} mb={[2, 0]} sx={{ flexGrow: '1' }}>
                <FormInputWithLabel
                  title={translate('your_email')}
                  label={translate('your_email')}
                  aria-label={translate('your_email')}
                  placeholder={translate('your_email')}
                  defaultValue={user?.data?.email}
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: translate('validation.invalid_email'),
                    },
                  })}
                  name="email"
                  sx={{ width: '100%' }}
                />
              </Box>
              <Button>
                {loading && (
                  <Spinner
                    size="15"
                    color="inherit"
                    title="Submitting"
                    sx={{
                      '@media (prefers-reduced-motion)': {
                        circle: {
                          animationIterationCount: 8,
                        },
                      },
                    }}
                  />
                )}
                {!loading && <Text>{'Sign up'}</Text>}
              </Button>
            </Flex>
            {errors && errors['email'] && errors['email'].type === 'required' && (
              <Text mt={1} variant="error">
                {translate('validation.required_field')}
              </Text>
            )}
            {errors && errors['email'] && errors['email'].type === 'pattern' && (
              <Text mt={1} variant="error">
                {translate('validation.invalid_email')}
              </Text>
            )}
          </Box>
        </Fragment>
      )}

      {submitted && (
        <Box>
          <Heading as="h3" variant="title3" mb={1}>
            {'Tasting Panels'}
          </Heading>
          <Text as="p" variant="body2" m={0}>
            {"Thanks for your interest. We'll be in touch shortly!"}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default AccountDashboardCommunityTastingPanelForm
