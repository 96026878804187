/** @jsx jsx */
import { Fragment } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Button, Flex, Grid, Heading, jsx, Text } from 'theme-ui'
import { getClubJoinDefaultItems } from '~/utils/subscriptions'

const AccountDashboardClubEmptyStateItem = ({ blob, icon, label }) => {
  return (
    <Flex sx={{ alignItems: 'center', flexDirection: 'column' }}>
      <Flex
        mb={2}
        sx={{
          backgroundImage: `url('${blob}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100px',
          justifyContent: 'center',
          position: 'relative',
          width: '108px',
        }}
      >
        {icon}
      </Flex>
      <Text
        as="p"
        variant="callout1"
        sx={{
          maxWidth: '108px',
          textAlign: 'center',
        }}
      >
        {label}
      </Text>
    </Flex>
  )
}

const AccountDashboardClubEmptyStateTop = () => {
  const blobs = [
    '/images/backgrounds/icon-list-item-blob-1.svg',
    '/images/backgrounds/icon-list-item-blob-2.svg',
    '/images/backgrounds/icon-list-item-blob-3.svg',
    '/images/backgrounds/icon-list-item-blob-4.svg',
  ]
  const icons = [
    <img
      src="/images/icons/shipping.svg"
      alt="A shipping box icon"
      sx={{ height: '35px', width: '51px' }}
    />,
    <img
      src="/images/icons/can.svg"
      alt="A can icon"
      sx={{ height: '48px', width: '41px' }}
    />,
    <img
      src="/images/icons/award.svg"
      alt="An award ribbon icon"
      sx={{ height: '51px', width: '66px' }}
    />,
    <img
      src="/images/icons/cheers.svg"
      alt="An icon of two glasses touching each other"
      sx={{ height: '47px', width: '44px' }}
    />,
  ]
  const labels = [
    'Free shipping',
    '10 - 20% off all wines',
    'Access to members only wines',
    'Exclusive tastings + merch',
  ]

  return (
    <Box
      bg="backgroundBright"
      pt={5}
      px={[2, 3]}
      pb={[9, 10]}
      sx={{
        position: 'relative',
        '&:after': {
          backgroundColor: 'backgroundLight',
          backgroundImage: `url('/images/backgrounds/numbered-steps-background-2.svg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          bottom: '0',
          content: '""',
          display: 'block',
          height: '32px',
          left: '50%',
          pointerEvents: 'none',
          position: 'absolute',
          transform: 'translateX(-50%)',
          width: '2000px',
        },
      }}
    >
      <Box mb={4} sx={{ textAlign: 'center' }}>
        <Heading as="h2" variant="title3" mb={[1, 2]}>
          {'Join the Can Club'}
        </Heading>
        <Text as="p" variant="body2" m={0}>
          {
            'Each quarter we curate a box including limited, members-only wines. You’ll also enjoy these membership perks:'
          }
        </Text>
      </Box>
      <Grid columns={[2, 4]} mb={4} sx={{ rowGap: 5 }}>
        {blobs.map((blob, index) => (
          <AccountDashboardClubEmptyStateItem
            key={index}
            blob={blob}
            icon={icons[index]}
            label={labels[index]}
          />
        ))}
      </Grid>
      <Box sx={{ textAlign: 'center' }}>
        <Button as={Link} to="/can-club/">
          {'Join the club'}
        </Button>
      </Box>
    </Box>
  )
}

const AccountDashboardClubEmptyStateNext = ({
  allProductContent,
  subscriptionContent,
}) => {
  const defaultItems = getClubJoinDefaultItems(
    subscriptionContent,
    allProductContent
  )
  const { defaultVariantsDescription } = subscriptionContent

  return (
    <Box pt={[5, 6]} px={[2, 3]}>
      <Heading as="h2" variant="title3" mb={[2, 3]}>
        {'Next Club shipment:'}
      </Heading>
      <Box
        bg="backgroundMedium"
        p={[2, 3]}
        mb={3}
        sx={{ borderRadius: '10px' }}
      >
        <Heading as="h3" variant="title6" mb={1}>
          {'Join the Can Club today and you can enjoy this next club shipment!'}
        </Heading>
        <Text as="p" variant="body2" m={0}>
          Select our picks or customize based on what you love. No commitments -
          It's easy to pause, skip, or cancel your membership at any time.
        </Text>
      </Box>
      <Box bg="white" px={[2, 3]} py={3} sx={{ borderRadius: '10px' }}>
        <Grid columns={1} sx={{ rowGap: 2 }}>
          {defaultItems &&
            Object.keys(defaultItems).map((sku) => {
              const defaultItem = defaultItems[sku]
              const { description, thumbnail, name, quantity } = defaultItem

              if (quantity === 0) {
                return <Fragment key={sku} />
              }

              return (
                <Flex key={sku} sx={{ alignItems: ['flex-start', 'center'] }}>
                  <Box
                    mr={2}
                    sx={{
                      flexBasis: ['80px', '104px'],
                      flexGrow: '0',
                      flexShrink: '0',
                    }}
                  >
                    <GatsbyImage
                      image={thumbnail.gatsbyImageData}
                      alt={thumbnail.description}
                    />
                  </Box>
                  <Box sx={{ flexGrow: '1' }}>
                    <Text as="h5" variant="title6" m={0} mb="4px">
                      {name}
                    </Text>
                    {description && (
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: description.childMarkdownRemark.html,
                        }}
                        variant="description"
                        sx={{ color: 'greyDark', p: { m: 0 } }}
                      />
                    )}
                  </Box>
                </Flex>
              )
            })}
        </Grid>
        {defaultVariantsDescription && (
          <Box
            mt={2}
            pt={2}
            sx={{ borderTop: '1px solid', borderTopColor: 'greyLight' }}
          >
            <Heading as="h4" variant="body2">
              {'Maker note:'}
            </Heading>
            <Text
              dangerouslySetInnerHTML={{
                __html: defaultVariantsDescription.childMarkdownRemark.html,
              }}
              variant="body3"
              sx={{
                p: {
                  '&:first-of-type': {
                    mt: 0,
                  },
                  '&:last-of-type': {
                    mb: 0,
                  },
                },
              }}
            />
          </Box>
        )}
      </Box>
      <Box mt={[4, 5]} sx={{ textAlign: 'center' }}>
        <Button as={Link} to="/can-club/">
          {'Join the club'}
        </Button>
      </Box>
    </Box>
  )
}

const AccountDashboardClubEmptyState = ({
  allProductContent,
  allSubscriptionContent,
}) => {
  return (
    <Box mt={[-3, -4, -5]} mx={[-2, -3]} sx={{ overflow: 'hidden' }}>
      <AccountDashboardClubEmptyStateTop />
      <AccountDashboardClubEmptyStateNext
        allProductContent={allProductContent}
        subscriptionContent={allSubscriptionContent[0]}
      />
    </Box>
  )
}

export default AccountDashboardClubEmptyState
